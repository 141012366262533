// eslint-disable-next-line import/prefer-default-export
export const tableListPartner = [
  {
    key: 'name',
    label: 'Partner',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    thClass: 'text-start',
    tdClass: 'text-black',
  },
  {
    key: 'products',
    label: 'Product',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    thClass: 'text-center',
    tdClass: 'text-black',
  },
  {
    key: 'created_at',
    label: 'Waktu Pendaftaran',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    thClass: 'text-center',
    tdClass: 'text-black',
  },
  {
    key: 'kmpoin',
    label: 'Saldo Aktif',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    thClass: 'text-center w-[300px]',
    tdClass: 'text-black w-auto',
  },
  {
    key: 'id',
    label: '',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      background: '#F4F4F4',
    },
    thClass: 'text-center',
    tdClass: 'text-black',
  },
]

export const defaultProductKomshipFilter = [
  {
    keyword: '',
    limit: 20,
    page: 0,
    order: 'terbaru',
  },
  {
    keyword: '',
    limit: 20,
    page: 0,
  },
  {
    keyword: '',
    limit: 20,
    page: 0,
    order: '',
    start_date: '',
    end_date: '',
    warehouses: [],
    products: [],
    payment_method: [],
    status: [],
    status_retur: [],
  },
]

const fieldListProductKomship = [
  { key: 'name', label: 'Nama Produk' },
  { key: 'variant', label: 'Variasi' },
  { key: 'weight', label: 'Berat' },
  { key: 'volume', label: 'Volume' },
  { key: 'price', label: 'Harga Produk' },
  { key: 'stock', label: 'Stok' },
  { key: 'sold', label: 'Terjual' },
]

const fieldListWarehouseKomship = [
  { key: 'name', label: 'Nama Gudang' },
  { key: 'address', label: 'Alamat Lengkap' },
  { key: 'zip_code', label: 'Zip Code' },
  { key: 'pic_name', label: 'Nama PIC' },
  { key: 'contact', label: 'Kontak PIC' },
]

const fieldListOrderKomship = [
  { key: 'order_date', label: 'Tanggal Order' },
  { key: 'customer', label: 'Pembeli' },
  { key: 'resi', label: 'No. Order dan Resi' },
  { key: 'product', label: 'Produk' },
  { key: 'customer_address', label: 'Alamat Pengiriman' },
  { key: 'receive_date', label: 'Tanggal Diterima' },
  { key: 'amount', label: 'Pembayaran' },
  { key: 'status', label: 'Status Order' },
  { key: 'status_retur', label: 'Status Retur' },
  { key: 'action', label: '' },
]

const transformField = field => field.map(f => ({
  ...f,
  ...{
    thStyle: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#333333',
      backgroundColor: '#F8F8F8',
      textTransform: 'none',
      whiteSpace: 'nowrap',
    },
    tdClass: 'text-[#828282] text-[14px]',
  },
}))

export const tableListProductKomship = transformField(fieldListProductKomship)
export const tableListWarehouseKomship = transformField(fieldListWarehouseKomship)
export const tableListOrderKomship = transformField(fieldListOrderKomship)
