<template>
  <div>
    <B-card>
      <div class="text-black text-2xl font-semibold">
        Information
      </div>
      <div class="mt-3 border rounded p-2 d-flex flex-column w-80">
        <span class="d-flex flex-row align-items-center mb-[10px]">
          <img
            src="https://storage.googleapis.com/komerce/assets/icons/icon-jumlah-partner.svg"
            alt="jumlah-partner"
            class="mr-[5px]"
          >
          Jumlah Partner
        </span>
        <div class="d-flex align-items-center">
          <span class="text-black text-3xl font-semibold">
            {{ totalPartner }}
          </span>
          <span
            id="newpartner"
            class="rounded px-1 new-partner ml-1"
          >+{{ newPartner }}</span>
          <b-popover
            target="newpartner"
            placement="top"
            triggers="hover focus"
            content="Jumlah update user yang bertambah bulan ini"
          />
        </div>
      </div>
      <b-form-row class="align-items-md-center mt-2">
        <b-col>
          <b-card-title class="mb-1 mb-md-0 text-black">
            Data Partner
          </b-card-title>
        </b-col>
        <b-col
          md="auto"
          class="d-flex"
        >
          <b-form-group class="mb-0">
            <b-input-group
              class="input-group-merge"
              size="md"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                placeholder="Cari partner, email"
                debounce="500"
                @input="searchData"
              />
            </b-input-group>
          </b-form-group>
          <b-button
            variant="primary"
            class="p-0 rounded-lg align-self-start position-relative focus:outline-none ml-1"
            @click="set => dropdownFilter = !dropdownFilter"
          >
            <img
              class="w-10 p-[5px]"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
              alt=""
            >
          </b-button>
          <ul
            v-if="dropdownFilter"
            class="d-block position-absolute bg-[#f9f9f9] z-10 text-black font-medium text-[12px] pb-1"
          >
            <div class="text-black pl-2 py-1 text-[16px] border">
              Filter
            </div>
            <div
              class="hover:bg-[#FCD4BE] border"
              :class="lastActive === true ? '' : 'bg-[#FCD4BE]'"
            >
              <date-range-picker
                ref="picker"
                v-model="dateRange"
                :locale-data="locale"
                :ranges="ranges"
                :opens="'left'"
                class="w-100"
                control-container-class="border-0"
                @update="lastActive = false"
              >
                <template v-slot:input="picker">
                  <div class="d-flex btn">
                    <span>Waktu Dibuat</span>
                  </div>
                  <span class="d-none">{{ picker }}</span>
                </template>
              </date-range-picker>
            </div>
            <div
              class="hover:bg-[#FCD4BE]"
              :class="lastActive === true ? 'bg-[#FCD4BE]' : ''"
            >
              <date-range-picker
                ref="picker"
                v-model="dateRangeLast"
                :locale-data="locale"
                :ranges="rangesLast"
                :opens="'left'"
                class="w-100"
                control-container-class="border-0"
                @update="lastActive = true"
              >
                <template v-slot:input="picker">
                  <div class="d-flex btn">
                    <span>Terakhir Aktif</span>
                  </div>
                  <span class="d-none">{{ picker }}</span>
                </template>
              </date-range-picker>
            </div>
            <div class="border-t-2 pt-1 justify-between d-flex gap-4 px-1">
              <b-button
                variant="outline-primary"
                class="w-30 rounded"
                style="font-size: 12px !important"
                @click="clearFilter()"
              >
                Reset Filter
              </b-button>
              <b-button
                type="submit"
                variant="primary"
                class="w-30 rounded"
                style="font-size: 12px !important"
                @click="getData()"
              >
                Terapkan
              </b-button>
            </div>
          </ul>
        </b-col>
      </b-form-row>
      <BOverlay
        id="scroll"
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          id="table"
          :items="items"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
          sticky-header="500px"
          class="mt-2"
          @scroll.native="handleScroll"
        >
          <template #cell(name)="data">
            <div class="flex flex-column text-left">
              <span class="font-bold">{{ data.item.name }}</span>
              <span>{{ data.item.email }}</span>
            </div>
          </template>
          <template #cell(products)="data">
            <div class="d-flex justify-content-center grid-5">
              <div
                v-for="(logo, index) in data.item.products"
                :key="index"
              >
                <img
                  :src="logo.url_image"
                  :alt="logo.product_name"
                  style="max-width: 20px"
                >
              </div>
              <span
                v-if="data.item.products === null"
                class="flex justify-content-center"
              >-</span>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="flex justify-content-center">
              {{ DAY_MONTH_YEAR(data.item.created_at) }}
            </div>
          </template>
          <template #cell(kmpoin)="data">
            <div class="flex justify-content-center align-items-center">
              <span
                v-if="data.item.isShow"
                class="d-flex"
              >
                {{ IDR(data.item.kmpoin) }}
                <b-button
                  variant="light"
                  class="p-0 ml-[5px]"
                  @click="toggleBalance(data.item)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/icons/eye-slash.svg"
                    alt="eye"
                    width="20px"
                  >
                </b-button>
              </span>
              <span
                v-else
                class="d-flex"
              >*******
                <b-button
                  variant="light"
                  class="p-0 ml-[5px]"
                  @click="toggleBalance(data.item)"
                >
                  <img
                    src="https://storage.googleapis.com/komerce/assets/icons/eye-blue.svg"
                    alt="eye"
                    width="20px"
                  >
                </b-button></span>
            </div>
          </template>
          <template #cell(id)="data">
            <div class="flex justify-content-center">
              <a
                :href="`/information/details/${data.item.id}`"
                style="color: #08A0F7 !important"
              >Lihat Detail</a>
            </div>
          </template>
        </BTable>
      </BOverlay>
    </B-card>
    <ModalPin />
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable array-callback-return */
import {
  BRow,
  BButton,
  BSpinner,
  BFormRow,
  BCol,
  BCard,
  BCardTitle,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  YEAR_MONTH_DAY, DAY_MONTH_YEAR, LABELDAY, LABELMONTH, START_OF, SUBTRACT_OF, DATE,
} from '@/libs/filterDate'
import moment from 'moment'
import {
  today,
  last7,
  last2,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
  tomorrow,
} from '@/store/helpers'
import { newAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import { tableListPartner } from './config'
import ModalPin from './ModalPIN.vue'

export default {
  components: {
    BFormRow,
    BCol,
    BCard,
    BCardTitle,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    DateRangePicker,
    ModalPin,
  },
  data() {
    return {
      loading: false,
      dropdownFilter: false,
      items: [],
      limit: 20,
      offset: 0,
      lastData: false,
      search: '',
      fields: tableListPartner,
      lastActive: false,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      SUBTRACT_OF,
      START_OF,
      LABELDAY,
      LABELMONTH,
      IDR,
      picker: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Bulan Ini': [firstDateOfMonth, today],
        'Bulan Lalu': [new Date(SUBTRACT_OF(1, 'months').startOf('month').format('YYYY-MM-DD')), new Date(SUBTRACT_OF(1, 'months').endOf('month').format('YYYY-MM-DD'))],
        'Custom Range': [null, null],
      },
      rangesLast: {
        'Hari Ini ': [today, today],
        'Minggu Ini': [new Date(START_OF('week', 'YYYY-MM-DD')), tomorrow],
        'Bulan Ini': [firstDateOfMonth, today],
        'Custom Range': [null, null],
      },
      today,
      last7,
      last2,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      dateRange: {
        startDate: last30,
        endDate: today,
      },
      dateRangeLast: {
        startDate: last30,
        endDate: today,
      },
      totalPartner: 0,
      newPartner: 0,
      countDataList: 0,
      isShow: false,
    }
  },
  mounted() {
    this.getTotalPartner()
    this.getData()
  },
  methods: {
    formatDateParams(d) {
      return moment(d)
        .format('YYYY-MM-DD')
    },
    toggleBalance(item) {
      this.$set(item, 'isShow', !item.isShow)
    },
    clearFilter() {
      // eslint-disable-next-line no-unused-expressions
      this.dateRange = {
        startDate: last30,
        endDate: today,
      // eslint-disable-next-line no-sequences
      },
      this.search = '',
      this.lastActive = false,
      this.limit = 20,
      this.offset = 0,

      this.getData()
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target

      if (scrollTop + clientHeight >= scrollHeight - 1 && !this.loading
      && !this.lastData) {
        this.getNextData()
      }
    },
    async getTotalPartner() {
      const url = '/auth/api/v1/admin/partner/information/user/count'
      await newAxiosIns
        .get(url)
        .then(res => {
          this.totalPartner = res.data.data.count
          this.newPartner = res.data.data.count_new_user
        })
        .catch(err => {
          this.$toast_error({ message: 'Gagal Mengambil Data Jumlah Partner' })
        })
    },
    searchData: _.debounce(function search(text) {
      this.offset = 0
      this.getData()
    }, 1000),
    async getData() {
      const params = {
        search: this.search,
        limit: this.limit,
        offset: 0,
        filter: this.lastActive ? 'last_active' : 'created',
        start_at: this.formatDateParams(this.lastActive ? this.dateRangeLast.startDate : this.dateRange.startDate),
        end_at: this.formatDateParams(this.lastActive ? this.dateRangeLast.endDate : this.dateRange.endDate),
      }

      const url = '/auth/api/v1/admin/partner/information/user'

      try {
        const { data } = await newAxiosIns.get(url, { params })
        const { list, count } = data.data
        this.items = list
        this.lastData = count < this.limit
        this.offset = list.length
      } catch (err) {
        this.$toast_error({ message: 'Gagal Mengambil Data' })
        this.loading = false
      }
    },
    getNextData() {
      if (!this.lastData) {
        this.loading = true

        const params = {
          search: this.search,
          limit: this.limit,
          offset: this.offset,
          filter: this.lastActive ? 'last_active' : 'created',
          start_at: this.formatDateParams(this.lastActive ? this.dateRangeLast.startDate : this.dateRange.startDate),
          end_at: this.formatDateParams(this.lastActive ? this.dateRangeLast.endDate : this.dateRange.endDate),
        }

        const url = '/auth/api/v1/admin/partner/information/user'

        newAxiosIns
          .get(url, { params })
          .then(res => {
            const { list } = res.data.data
            this.loading = false
            this.items.push(...list)
            this.offset += list.length
            if (list.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loading = false
            this.$toast_error({ message: 'Gagal load data' })
          })
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
